import { AWAITING_VALIDATION, CREATING, VALIDATED } from '@/constants/product'

export default {
  methods: {
    resolveStatusVariantAndIcon(status) {
      if (status === 'Completed') return { variant: 'success', icon: 'CheckCircleIcon' }
      if (status === 'In progress') return { variant: 'primary', icon: 'InfoIcon' }
      if (status === 'Action required') return { variant: 'warning', icon: '' }
      if (status === 'Slot required') return { variant: 'warning', icon: '' }
      if (status === 'Fluid') return { variant: 'success', icon: '' }
      return { variant: 'secondary', icon: 'XIcon' }
    },

    resolveRoleVariant(role) {
      if (role === 'subscriber') return 'primary'
      if (role === 'author') return 'warning'
      if (role === 'maintainer') return 'success'
      if (role === 'editor') return 'info'
      if (role === 'admin') return 'danger'
      return 'primary'
    },

    resolveRoleIcon(role) {
      if (role === 'FO') return 'plane'
      if (role === 'FBO') return 'user-tie'
      if (role === 'BO') return 'user-cog'
      if (role === 'subscriber') return 'user'
      if (role === 'author') return 'cog'
      if (role === 'maintainer') return 'database'
      if (role === 'editor') return 'pencil-alt'
      if (role === 'admin') return 'server'
      return 'user'
    },

    resolveColor(status) {
      let color = 'success'
      if (status.toLowerCase().startsWith('await')) {
        color = 'warning'
      }
      if (status.toLowerCase().startsWith('valid')) {
        color = 'success'
      }
      return color
    },

    // TODO: check and finish it
    resolveStatusVariant(status) {
      const variants = {
        // [status]: 'variant'
        [false]: 'danger',
        [CREATING]: 'warning',
        [AWAITING_VALIDATION]: 'warning',
        [VALIDATED]: 'success',
      }
      return variants[status] || 'success'
    },

    // TODO: check and finish it (with i18n key)
    resolveStatusText(status) {
      const texts = {
        [CREATING]: 'Pending',
        [AWAITING_VALIDATION]: 'Awaiting validation',
        [VALIDATED]: 'Validated',
      }
      return texts[status] || 'Validated'
    },

    // TODO: check and finish it (with i18n key)
    resolveInvoiceStatusText(status) {
      const texts = {
        to_be_paid: 'To be paid',
        partial_payment: 'Partial payment',
        paid: 'Paid',
        retarded: 'Retarded',
        cancelled: 'Cancelled',
        claimed: 'Claimed',
      }
      return texts[status] || ''
    },

    resolveInvoiceStateVariant(state) {
      if (state === 'to_be_baid') return 'warning'
      if (state === 'partial_payment') return 'light-warning'
      if (state === 'paid') return 'success'
      if (state === 'retarded') return 'light-danger'
      if (state === 'cancelled') return 'danger'
      if (state === 'claimed') return 'warning'
      return 'primary'
    },

    resolveSlotStatusVariant(state) {
      if (state === 'not_requested') return 'outline-success'
      if (state === 'requested') return 'warning'
      if (state === 'submitted') return 'success'
      if (state === 'not_assigned') return 'danger'
      if (state === 'need_validation') return 'warning'
      return 'primary'
    },

    resolveQuoteStateVariant(state) {
      if (state === 'awaiting_for_quotation') return 'light-primary'
      if (state === 'awaiting_customer_validation') return 'light-warning'
      if (state === 'delivered') return 'success'
      if (state === 'claimed') return 'warning'
      return 'primary'
    },

    resolveQuoteStateColor(state) {
      if (state === 'awaiting_for_quotation') return '#FFF9B7'
      if (state === 'awaiting_customer_validation') return 'rgba(255, 159, 67, 0.8)'
      if (state === 'delivered') return '#28c76f'
      if (state === 'claimed') return '#ff9f43'
      if (state === 'cancelled' || state === 'refused' || state === 'cancelled_draft') return 'rgba(234,84,85, 0.8)'
      return '#DCC181'
    },

    resolveQuoteStateTextColor(state) {
      if (state === 'awaiting_for_quotation') return '#6e6b7b'
      if (state === 'awaiting_customer_validation') return '#6e6b7b'
      if (state === 'delivered') return '#ffffff'
      if (state === 'claimed') return '#ffffff'
      if (state === 'cancelled' || state === 'refused' || state === 'cancelled_draft') return '#6e6b7b'
      return '#ffffff'
    },

    resolveQuoteEventVariant(event) {
      if (
        event === 'quote_request'
        || event === 'quote_validate'
        || event === 'quote_send'
        || event === 'cancellation_policies_apply'
        || event === 'invoice_send'
      ) return 'success'
      if (
        event === 'quote_request_edit'
        || event === 'quote_edit'
        || event === 'invoice_request'
      ) return 'warning'
      if (event === 'quote_refuse' || event === 'quote_cancel') return 'danger'
      return 'primary'
    },

    resolveQuoteLineStateColor(status) {
      if (status.toLowerCase() === 'not_planned') {
        return 'danger'
      }

      if (status.toLowerCase() === 'planned') {
        return 'primary'
      }

      if (status.toLowerCase() === 'in_progress') {
        return 'info'
      }

      return 'success'
    },

    resolvePaymentIntentStatusVariant(status) {
      if (status === 'requires_payment_method' || status === 'requires_action') return 'light-danger'
      if (status === 'requires_confirmation') return 'light-warning'
      if (status === 'processing') return 'light-success'
      if (status === 'requires_capture') return 'warning'
      if (status === 'canceled') return 'danger'
      if (status === 'succeeded') return 'success'
      return 'secondary'
    },

    resolveSupplyLineStatusVariant(state) {
      if (state === 'not_planned') return 'danger'
      if (state === 'planned') return 'warning'
      if (state === 'in_progress') return 'light-success'
      if (state === 'delivered') return 'success'
      if (state === 'cancelled') return 'dark'
      return 'secondary'
    },

    resolveTimelineVariant(event) {
      const info = {
        icon: 'route',
        variant: 'info',
      }
      if (event === 'transfer') {
        info.icon = 'route'
        info.variant = 'info'
      }
      if (event === 'departure') {
        info.icon = 'plane-departure'
        info.variant = 'success'
      }
      if (event === 'arrival') {
        info.icon = 'plane-arrival'
        info.variant = 'success'
      }
      if (event === 'helicopter') {
        info.icon = 'helicopter'
        info.variant = 'info'
      }
      if (event === 'fuel') {
        info.icon = 'gas-pump'
        info.variant = 'info'
      }
      if (event === 'provider') {
        info.icon = 'user-cog'
        info.variant = 'info'
      }
      return info
    },

    avatarText(value) {
      if (!value) return ''
      const nameArray = value.split(' ')
      return nameArray.map(word => word.charAt(0).toUpperCase()).join('')
    },

    resolveClassStatusOrganization(status) {
      if (status === 'awaiting_validation') return 'warning'
      if (status === 'awaiting_operator_confirmation') return 'warning'
      if (status === 'awaiting_offer_selection') return 'warning'
      if (status === 'active') return 'success'
      if (status === 'inactive') return 'dark'

      return 'danger'
    },

    resolveClassStatusOrganizationUser(status) {
      if (status === 'inactive') return 'danger'
      if (status === 'active') return 'success'
      if (status === 'waiting_confirmation') return 'warning'
      if (status === 'expired_token') return 'light-danger'

      return 'danger'
    },

    resolveTextStatusOrganization(status) {
      if (status === 'awaiting_validation') return 'Awaiting validation'
      if (status === 'awaiting_operator_confirmation') return 'Awaiting operator confirmation'
      if (status === 'awaiting_offer_selection') return 'Awaiting offer selection'
      if (status === 'active') return 'Active'
      if (status === 'inactive') return 'Inactive'

      return 'Deleted'
    },
  },
}
