<template>
  <div>
    <!-- FILTERS DATE-->
    <b-card>
      <b-row>
        <b-col md="3">
          <b-form-group :label="$t('common.start_at')" label-for="start-at">
            <b-form-datepicker id="start-at" v-model="params.startAt" :locale="$i18n.locale" :max="params.endAt" size="md" />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group :label="$t('common.end_at')" label-for="end-at">
            <b-form-datepicker id="en-at" v-model="params.endAt" :locale="$i18n.locale" :min="params.startAt" size="md" />
          </b-form-group>
        </b-col>
        <!--Filter : CLIENT-->
        <b-col md="3">
          <app-input id="search" v-model="params.legalName" :label="$t('accounting.client')" />
        </b-col>
        <!--Filter : PAYMENT METHOD-->
        <b-col md="3">
          <enum-select
            id="event-payment-method-type"
            v-model="params.paymentMethodsType"
            :label="$t('common.payment')"
            enum-class="PaymentMethodsType"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col class="d-flex justify-content-end">
          <b-button :disabled="params.startAt === null && params.endAt === null && params.legalName === null && params.paymentMethodsType === null" class="mt-1" type="reset" variant="outline-secondary" @click.prevent="resetFilters()">{{ $t('action.clear') }} </b-button>
        </b-col>
      </b-row>
    </b-card>
    <!-- LIST MODE -->
    <b-card>
      <app-data-table
        ref="refPaymentsListTable"
        :bottom-table="{ totalCount: totalItems }"
        :busy="paymentsLoading"
        :fields="tableColumns"
        :items="payments"
        :page="params.page"
        :per-page="params.numberOfItemsPerPage"
        :sort-by.sync="sort.sortBy"
        :sort-desc.sync="sort.sortDesc"
        manual-pagination
        table-name="payment-list"
        @pagination="params.page = $event"
        @table-select-per-page="params.numberOfItemsPerPage = $event"
        @table-filter="allFilters = $event"
      >
        <template #cell(date)="data">
          <div v-if="data.item.date">
            {{ $moment.utc(data.item.date).format('ddd, MMM Do YYYY HH:mm') }} {{ $t('common.utc') }}
          </div>
        </template>
        <template #cell(currency)="data">
          <div>
            <span> {{ data.item.currency.code }} </span>
          </div>
        </template>
        <template #cell(client)="data">
          <div>
            <span> {{ data.item.client.legalName }} </span>
          </div>
        </template>
        <template #cell(quote)="data">
          <div>
            <span> {{ data.item.quote.reference }} </span>
          </div>
        </template>
        <template #cell(paymentMethod)="data">
          <div>
            <span> {{ data.item.paymentMethod.type | enumTranslate('payment_methods_type') }} </span>
          </div>
        </template>
        <template #cell(availabilityDate)="data">
          <div v-if="data.item.availabilityDate">
            {{ $moment.utc(data.item.availabilityDate).format('ddd, MMM Do YYYY HH:mm') }}
          </div>
        </template>
      </app-data-table>
    </b-card>
  </div>
</template>

<script>

import UIHelper from '@/helpers/ui'
import { fetchOrganizationPaymentRequest } from '@/request/globalApi/requests/organizationRequests'
import { mapState } from 'vuex'
import AppDataTable from '@/components/AppDataTable.vue'

export default {
  name: 'DashboardSellerList',

  components: {
    AppDataTable,
  },
  mixins: [UIHelper],
  data() {
    return {
      legalNameTyping: false,
      payments: [],
      totalItems: 0,
      params: {
        // PAGE
        numberOfItemsPerPage: 10,
        page: 1,
        // SEARCH
        legalName: null,
        startAt: null,
        endAt: null,
        paymentMethodsType: null,
      },
      sort: {
        sortBy: 'date',
        sortDesc: 'desc',
      },
      paymentsLoading: false,
      tableColumns: [
        { key: 'date', label: this.$t('dashboard.seller.columns.date_move'), sortable: true },
        { key: 'net', label: this.$t('dashboard.seller.columns.net') },
        { key: 'amount', label: this.$t('dashboard.seller.columns.amount') },
        { key: 'fee', label: this.$t('dashboard.seller.columns.fee') },
        { key: 'currency', label: this.$t('dashboard.seller.columns.currency') },
        { key: 'client', label: this.$t('dashboard.seller.columns.customer') },
        { key: 'quote', label: this.$t('dashboard.seller.columns.quote'), sortable: true },
        { key: 'paymentMethod', label: this.$t('dashboard.seller.columns.payment_method') },
        { key: 'availabilityDate', label: this.$t('dashboard.seller.columns.available_at'), sortable: true },
      ],
    }
  },
  computed: {
    ...mapState('auth', ['selectedOrganization']),
    allFilters() {
      return {
        numberOfItemsPerPage: this.params.numberOfItemsPerPage,
        page: this.params.page,
        // SEARCH
        legalName: this.params.legalName,
        startAt: this.params.startAt,
        endAt: this.params.endAt,
        paymentMethodsType: this.params.paymentMethodsType,
      }
    },
  },
  watch: {
    legalNameTyping: {
      handler(newVal) {
        if (!newVal) {
          this.refetchData()
        }
      },
    },
    allFilters: {
      handler(newVal, oldVal) {
        if (newVal.legalName !== oldVal.legalName && !this.legalNameTyping) {
          this.legalNameTyping = true
          setTimeout(() => {
            this.legalNameTyping = false
          }, 1000)
        } else if (newVal.legalName === oldVal.legalName) {
          this.refetchData()
        }
      },
      deep: true,
    },
    sort: {
      handler() {
        this.refetchData()
      },
      deep: true,
    },
  },
  mounted() {
    this.APIFetchPayments()
  },
  methods: {
    APIFetchPayments() {
      this.paymentsLoading = true
      const payload = {}

      if (this.sort.sortBy) {
        payload[`${this.sort.sortBy}Order`] = this.sort.sortDesc ? 'desc' : 'asc'
      }

      fetchOrganizationPaymentRequest(this.selectedOrganization.id, { ...this.params, ...payload })
        .then(response => {
          const { payments, totalItems } = response.data
          this.totalItems = totalItems
          this.payments = payments
          this.paymentsLoading = false
        })
    },
    resetFilters() {
      this.params.legalName = null
      this.params.startAt = null
      this.params.endAt = null
      this.params.paymentMethodsType = null
      this.params.page = 1
      this.APIFetchPayments()
    },
    refetchData() {
      this.APIFetchPayments()
    },
  },
}

</script>
